@import '../../../slick-ui/build/scss/_variables';

@media print {
    .rt-tr-group {
        break-inside: avoid;
        position: relative;
    }

    .rt-tr {
        display: block;
        break-inside: avoid-page;
        position: relative;
    }

    .rt-tbody {
        display: block !important;
        position: relative;
    }

    .rt-table {
        display: block !important;
        position: relative;
    }
}

.eval-table .rt-resizable-header-content {
    height: 20px;
    color: $color-content-nonessential;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-right: none;
}

.ReactTable .rt-tbody {
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

.ReactTable .rt-tbody .rt-td {
    border-right: none;
    color: $color-content-default;
    font-family: 'Helvetica Neue', sans-serif;
}

.eval-table.ReactTable .rt-tbody .rt-td {
    font-size: 16px;
    line-height: 35px;
}

.eval-table.ReactTable .rt-thead .rt-tr {
    text-align: left;
    text-transform: uppercase;
}

.ReactTable .rt-thead.-header {
    border-bottom: 1px solid $color-environment-light-divider !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.eval-table.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid $color-environment-light-level-0-accent !important;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-height: 51px;
    }
}

.ReactTable .rt-head:focus,
.rt-th:focus {
    outline: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: $color-selection-hover;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):active {
    background-color: $color-selection-select;
}
