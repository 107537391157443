@import './slick-ui/build/scss/_variables';

//font size - 14
// line height - 20
//noinspection ALL

.text-small {
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: $size-font-text-small !important;
    line-height: $size-lineheight-text-small !important;
}

%text-tile-caps {
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: $size-font-item-title-caps !important;
    letter-spacing: $size-letterspacing-item-title-caps !important;
    line-height: $size-lineheight-item-title-caps !important;
}

%label-small {
    font-size: $size-font-label-small !important;
    line-height: $size-lineheight-label-small !important;
}

.item-title {
    font-size: $size-font-item-title !important;
    line-height: $size-lineheight-item-title !important;
    letter-spacing: $size-letterspacing-item-title !important;
}

.text {
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: $size-font-text !important;
    font-weight: 400 !important;
    line-height: $size-lineheight-text !important;
}

.text-micro {
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: $size-font-text-micro;
    font-weight: normal;
    line-height: $size-lineheight-text-micro;
}

%button-text {
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: $size-font-label-small; //14px;
    font-weight: bold;
    line-height: $size-lineheight-label-small;
}

.large-button-text {
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: $size-font-label;
    font-weight: bold;
    line-height: $size-lineheight-label;
}

.default-text {
    @extend .text;
    color: $color-content-default !important;
    font-weight: 500 !important;
}

.normal-default-text {
    @extend .text;
    color: $color-content-default !important;
    font-weight: 400 !important;
}

.tight-default-text {
    @extend .text;
    line-height: $size-lineheight-text-tight !important;
    color: $color-content-default !important;
}

.contrast-text-small {
    @extend .text-small;
    color: $color-content-contrast !important;
}

.contrast-text {
    @extend .text;
    color: $color-content-contrast !important;
    font-weight: 400 !important;
}

/*
.item-title-caps {
	height: 16.3px;
	width: 105px;
	color: rgba(66,51,19,0.4);
	font-family: "Helvetica Neue";
	font-size: 12px;
	font-weight: bold;
	line-height: 16px;
}
 */
.subtle-text-small {
    @extend .text-small;
    color: $color-content-subtle !important;
}

.subtle-text-small-bold {
    @extend .text-small;
    color: $color-content-subtle !important;
    font-weight: bold;
}

.subtle-text-micro {
    @extend .text-micro;
    color: $color-content-subtle !important;
}

.subtle-text-small-bold {
    @extend .text-small;
    color: $color-content-subtle !important;
    font-weight: bold;
}

.default-text-small {
    @extend .text-small;
    color: $color-content-default !important;
}

.default-text-small-bold {
    @extend .text-small;
    color: $color-content-default !important;
    font-weight: bold !important;
}

.label-text-small {
    @extend %label-small;
    color: $color-content-default !important;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
}

.default-label-small {
    @extend %label-small;
    color: $color-content-default !important;
}

.default-item-title {
    @extend .item-title;
    color: $color-content-default !important;
}

.label-text {
    color: $color-content-default !important;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: $size-font-label;
    line-height: $size-lineheight-label;
}

.numbers-small {
    @extend .text-small;
    font-family: Menlo, monospace !important;
}

.numbers-contrast {
    color: $color-content-contrast !important;
    font-family: Menlo, monospace !important;
}

.numbers-default {
    @extend %label-small;
    color: $color-content-default !important;
    font-family: Menlo, monospace !important;
    //StyleDictionary is using rem but the number is in px. So number is converted from rem to px.
    letter-spacing: $size-letterspacing-numbers/16 !important;
}

.numbers-subtle {
    @extend %label-small;
    font-family: Menlo, monospace !important;
    color: $color-content-subtle !important;
    letter-spacing: $size-letterspacing-numbers/16 !important;
}

.secondary-minimal-button-small {
    @extend %button-text;
    color: '#4f2682' !important;
    text-align: center;
    &:disabled {
        color: $color-button-minimal-disabled !important;
    }
}

.minimal-button-small {
    @extend %button-text;
    text-align: center;

    &:disabled {
        color: $color-button-minimal-disabled !important;
    }
}

.secondary-minimal-tab-small {
    @extend %button-text;
    color: #4f2682 !important;
    line-height: 17px !important;
}

.nonessential-item-title {
    @extend .item-title;
    color: $color-content-nonessential !important;
    font-weight: bold;
}

.default-item-title {
    @extend .item-title;
    color: $color-content-default !important;
}

.nonessential-title-caps {
    @extend %text-tile-caps;
    color: $color-content-nonessential !important;
    font-weight: bold !important;
    text-transform: uppercase;
}

//Additional styles not in the style

.accent-text-small {
    @extend .text-small;
    color: $color-environment-light-level-3-accent !important;
}

.level-3-heading {
    color: $color-environment-dark-level-2-base !important;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 26px;
}

.dark-level-3-heading {
    color: $color-environment-dark-level-2-base !important;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 26px;
}

.level-2-heading {
    color: $color-environment-dark-level-2-base !important;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: 30px !important;
    font-weight: 300 !important;
    line-height: 34px !important;
    letter-spacing: -0.75px;
}

.level-1-heading {
    color: $color-environment-dark-level-2-base !important;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: 40px !important;
    font-weight: 300 !important;
    letter-spacing: -1px !important;
    line-height: 40px !important;
}

.provide-equipment-de {
    color: $color-content-contrast;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: 18px;
    line-height: 22px;
}

.column-title {
    padding-top: 3px;
    color: $color-content-nonessential;
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
}

.hours {
    color: $color-content-contrast;
    font-family: Menlo, monospace !important;
    font-size: $size-font-numbers !important;
    letter-spacing: $size-letterspacing-numbers/16 !important;
    // Lowered this because it was too large for the equipment list.
    line-height: 1.375rem !important; // 24px;
    text-transform: uppercase;
}

// Cannot find usage. Remove if determined that it is not used.
.disabled {
    background-color: $color-button-standard-disabled !important;
    opacity: 0.3 !important;
    color: #1d2426 !important;
}

.bold-default-text {
    @extend .text;
    color: $color-content-default !important;
    font-weight: bold !important;
}

.right-align {
    text-align: right;
}

.uploading {
    @extend .text-micro;
    color: $color-content-subtle-2;
    text-transform: uppercase;
}

.summary {
    color: $color-environment-dark-level-2-base;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: -0.75px;
    line-height: 34px;
}

.rthfc-td-fixed-right,
.rthfc-th-fixed-right {
    background-color: $color-environment-light-level-4-base !important;
    color: $color-content-default;
}

.rthfc-th-fixed-right svg {
    fill: $color-environment-light-level-4-accent !important;
}

.additional {
    height: 24px !important;
    color: $color-content-contrast !important;
    font-size: $size-font-text !important;
    line-height: $size-lineheight-text !important;
}
