@media print {
    header {
        display: none !important;
    }

    .no-print {
        display: none !important;
    }

    ::placeholder {
        color: transparent !important;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.button {
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 19px !important;
}

.amplify-container {
    padding: 0 !important;
}

.main-style {
    overflow: hidden;
    height: 100%;
    width: 100%;
}
