.image-zoomed {
    transition: transform 150ms linear 0ms;
    -webkit-transition: transform 150ms linear 0ms; /* Safari and Chrome */
    -moz-transition: transform 150ms linear 0ms; /* Firefox */
    -o-transition: transform 150ms linear 0ms; /* IE 9 */
    -ms-transition: transform 150ms linear 0ms; /* Opera */
    will-change: transform;
    user-select: none;
}

.image-dragging {
    transition: transform 40ms linear 0ms;
    -webkit-transition: transform 40ms linear 0ms; /* Safari and Chrome */
    -moz-transition: transform 40ms linear 0ms; /* Firefox */
    -o-transition: transform 40ms linear 0ms; /* IE 9 */
    -ms-transition: transform 40ms linear 0ms; /* Opera */
    will-change: transform;
    user-select: none;
}

.vc_single_image-wrapper {
    position: relative;
    height: 100%;
}

.vc_single_image-wrapper .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
