@import './slick-ui/build/scss/_variables';

.button {
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    padding: $size-space-half !important;
    width: 127px;
    height: 33px;
    margin-top: $size-space-half !important;
    margin-bottom: $size-space-half !important;
    white-space: nowrap;
    @media print {
        display: none !important;
    }
}

.sharebutton {
    text-transform: none !important;
    font-size: 16px !important;
    //font-weight: bold !important;
    line-height: 19px !important;
    padding: $size-space-half !important;
    width: 135px;
    height: 33px;
    margin-top: $size-space-half !important;
    margin-bottom: $size-space-half !important;
    white-space: nowrap;
    @media print {
        display: none !important;
    }
}

.minimal-button {
    @extend .button;
    color: $color-button-minimal-subtle !important;
    margin-left: $size-space-half !important;
    margin-right: $size-space-half !important;
}

.minimal-secondary-button {
    @extend .button;
    font-size: 14px !important;
    line-height: 17px !important;
    color: $color-button-minimal-secondary !important;
    text-transform: none !important;

    font-weight: bold !important;
    padding: $size-space-half !important;
    width: 100px;
    height: 33px;
    margin-top: $size-space-half !important;
    margin-bottom: $size-space-half !important;
    white-space: nowrap;
    @media print {
        display: none !important;
    }
}

.minimal-cancel-button {
    @extend .button;
    color: $color-button-minimal-cancel !important;
    font-weight: normal !important;
    margin-left: $size-space-half !important;
    margin-right: $size-space-half !important;
}

.minimal-destroy-button {
    @extend .button;
    color: $color-button-minimal-destroy !important;
    font-weight: bold !important;
    margin-left: $size-space-half !important;
    margin-right: $size-space-half !important;
}

.minimal-edit-button {
    @extend .button;
    color: rgb(10, 137, 57) !important;
    font-weight: bold !important;
    margin-left: $size-space-half !important;
    margin-right: $size-space-half !important;
}

.primary-color-button {
    color: $color-button-standard-label-default !important;
    background-color: #4f2682 !important;
    &:hover {
        background-color: #723cb5 !important;
    }
    &:disabled {
        color: $color-button-standard-label-disabled !important;
        background-color: $color-button-standard-disabled !important;
    }
}

.primary-button {
    @extend .button;
    @extend .primary-color-button;
}

.subtle-button {
    @extend .button;
    color: #4f2682 !important;
    background-color: $color-button-standard-subtle !important;
    &:disabled {
        color: #4f2682b0 !important;
    }
    &:hover {
        background-color: #4f268264 !important;
    }
}

.destroy-button {
    color: $color-button-standard-label-default !important;
    background-color: $color-button-standard-destroy !important;

    &:hover {
        background-color: $color-button-standard-destroy !important;
    }

    &:disabled {
        color: $color-button-standard-label-disabled !important;
        background-color: $color-button-standard-disabled !important;
    }
}

.secondary-color {
    color: $color-button-standard-label-default !important;
    background-color: $color-button-standard-secondary !important;

    &:hover {
        background-color: $color-button-standard-secondary !important;
    }

    &:disabled {
        color: $color-button-standard-label-disabled !important;
        background-color: $color-button-standard-disabled !important;
    }
}

.secondary-button {
    @extend .button;
    color: $color-button-standard-label-default !important;
    background-color: #4f2682 !important;
    &:hover {
        background-color: #7743b8 !important;
    }
    &:disabled {
        color: $color-button-standard-label-disabled !important;
        background-color: $color-button-standard-disabled !important;
    }
}
.share-secondary-button {
    @extend .sharebutton;
    color: $color-button-standard-label-default !important;
    background-color: #4f2682 !important;
    &:hover {
        background-color: #7743b8 !important;
    }
    &:disabled {
        color: $color-button-standard-label-disabled !important;
        background-color: $color-button-standard-disabled !important;
    }
}

.third-button {
    color: #4f2682 !important;
    background-color: #ffffff !important;
    border: 0.5px solid #4f2682 !important;

    &:hover {
        background-color: #c4c4c4 !important;
        border: 0px !important;
    }
}

.fourth-button {
    color: #ffffff !important;
    background-color: #4f2682 !important;
    box-shadow: none !important;

    &:hover {
        background-color: #7743b8 !important;
        border: 0px !important;
    }
}

.signin-minimal-button {
    color: $color-button-minimal-primary !important;
    font-size: $size-font-label;
    font-weight: bold;
    line-height: $size-lineheight-label;
    text-align: center;

    &:disabled {
        color: $color-button-minimal-primary !important;
    }
}
