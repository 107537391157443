@import './slick-ui/build/scss/_variables';

.table {
    display: table;
    border-collapse: collapse;
    margin-right: auto;
    margin-bottom: $size-space-one;
}

.table-row {
    display: table-row;
    padding-bottom: $size-space-half;
}

.table-cell,
.table-head {
    display: table-cell;
    padding-right: $size-space-half;
}

.table-head {
    font-weight: bold;
}

.grid-item-container {
    display: flex;
    //flex: 1 1;
}

.selected-thumbnail:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 4px solid $color-selection-dark;
    z-index: 1001;
}

.warning {
    color: $color-button-standard-warn;
}

// Addition UI odification overwrites

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow) {
    &:hover {
        background-color: rgba(223, 222, 222, 0.968) !important;
    }
}

.subtle-button {
    background-color: rgba(182, 181, 181, 0.968) !important;
}

// .MuiListItem-button {
//    &:hover {
//       background-color: rgba(226, 68, 68, 0.968) !important;
//    }
// }

// .MuiButton-label {
//    color: #ffffff !important;
// }

// .MuiSvgIcon-root {
//    color: #4f2682 !important;

// }

// .MuiPaginationItem-root[aria-disabled="true"] .MuiSvgIcon-root {
//    color: #999999 !important;
//  }

.MuiListItem-root.Mui-selected {
    background-color: rgba(223, 222, 222, 0.968) !important;
}

// .selected-thumbnail:after {
//    border: 4px solid #4f2682
// }
.selected-thumbnail:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #4f2682;
    box-sizing: border-box;
}

// .MuiIconButton-label {
//    color: #ffffff !important;
// }

// .minimal-cancel-button {
//    background-color: #4f2682 !important;
// }
