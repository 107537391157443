/**
 * Do not edit directly
 * Generated on Thu May 28 2020 08:58:07 GMT-0500 (Central Daylight Time)
 */

$color-brand-yellow: #ffdd50;
$color-brand-blue: #4986b3;
$color-brand-gray: #59585a;
$color-brand-tan: #fdfbff;
$color-brand-brown: #262527;
$color-button-standard-label-default: #ffffff;
$color-button-standard-label-subtle: #2c526e;
$color-button-standard-label-disabled: rgba(29, 36, 38, 0.2);
$color-button-standard-primary: #4986b3;
$color-button-standard-secondary: #165380;
$color-button-standard-subtle: rgba(73, 124, 179, 0.25);
$color-button-standard-destroy: #ce4543;
$color-button-standard-success: #2da970;
$color-button-standard-warn: #ff8e1c;
$color-button-standard-disabled: rgba(164, 167, 168, 0.3);
$color-button-minimal-primary: #4986b3;
$color-button-minimal-secondary: #165380;
$color-button-minimal-subtle: rgba(73, 133, 179, 0.7);
$color-button-minimal-destroy: #ca371d;
$color-button-minimal-success: #218556;
$color-button-minimal-warn: #db7916;
$color-button-minimal-disabled: rgba(97, 130, 142, 0.25);
$color-button-minimal-cancel: #878e94;
$color-button-checkbox-checked: #11a1ff;
$color-button-pin: #b0a99b;
$color-content-contrast: #262527;
$color-content-default: rgba(38, 37, 39, 0.8);
$color-content-subtle: rgba(38, 37, 39, 0.6);
$color-content-nonessential: rgba(38, 37, 39, 0.4);
$color-content-subtle-2: rgba(38, 37, 39, 0.3);
$color-content-subtle-3: rgba(93, 120, 143, 0.65);
$color-content-dark: rgba(93, 120, 143, 0.8);
$color-selection-hover: rgba(194, 227, 255, 0.6);
$color-selection-select: #c2e3ff;
$color-selection-dark: #75bfff;
$color-environment-light-level-0-base: #ffffff;
$color-environment-light-level-0-accent: #e4e2e6;
$color-environment-light-level-1-base: #fdfbff;
$color-environment-light-level-1-accent: #cac8cc;
$color-environment-light-level-2-base: #fdfbff;
$color-environment-light-level-2-accent: #b1afb3;
$color-environment-light-level-3-base: #cac8cc;
$color-environment-light-level-3-accent: #979599;
$color-environment-light-level-4-base: #dae4eb;
$color-environment-light-level-4-accent: #7e7c80;
$color-environment-light-level-4-accent-2: #646266;
$color-environment-light-divider: #b1afb3;
$color-environment-dark-level-0-base: #262527;
$color-environment-dark-level-0-accent: #424143;
$color-environment-dark-level-1-base: #424143;
$color-environment-dark-level-1-accent: #5e5d5f;
$color-environment-dark-level-2-base: #5e5d5f;
$color-environment-dark-level-2-accent: #7a797b;
$color-environment-dark-level-3-base: #7a797b;
$color-environment-dark-level-3-accent: #969597;
$color-environment-dark-divider: #5e5d5f;
$color-status-capture-in-progress: #7ed321;
$color-status-eval-requested: #f8ce1c;
$color-status-eval-in-progress: #4a90e2;
$color-status-in-review: #f57a23;
$color-status-finalized: #406370;
$color-status-archived: #d8d8d8;
$size-font-text: 1rem;
$size-font-text-tight: 1rem;
$size-font-text-small: 0.875rem;
$size-font-text-micro: 0.75rem;
$size-font-numbers: 1rem;
$size-font-label: 1rem;
$size-font-label-small: 0.875rem;
$size-font-item-title: 0.75rem;
$size-font-item-title-small: 0.625rem;
$size-font-item-title-caps: 0.75rem;
$size-font-navigation: 1.125rem;
$size-letterspacing-text: 0rem;
$size-letterspacing-text-tight: 0rem;
$size-letterspacing-text-small: 0rem;
$size-letterspacing-text-micro: 0rem;
$size-letterspacing-numbers: 0.5rem;
$size-letterspacing-label: 0rem;
$size-letterspacing-label-small: 0rem;
$size-letterspacing-item-title: 0rem;
$size-letterspacing-item-title-small: 0.8rem;
$size-letterspacing-item-title-caps: 0rem;
$size-letterspacing-navigation: 0rem;
$size-lineheight-text: 1.5rem;
$size-lineheight-text-tight: 1.125rem;
$size-lineheight-text-small: 1.25rem;
$size-lineheight-text-micro: 1rem;
$size-lineheight-numbers: 1.5rem;
$size-lineheight-label: 1.125rem;
$size-lineheight-label-small: 1.25rem;
$size-lineheight-item-title: 0.75rem;
$size-lineheight-item-title-small: 0.625rem;
$size-lineheight-item-title-caps: 1rem;
$size-lineheight-navigation: 1.375rem;
$size-space-eighth: 0.125rem;
$size-space-quarter: 0.25rem;
$size-space-half: 0.5rem;
$size-space-one: 1rem;
$size-space-one-and-half: 1.5rem;
$size-space-two: 2rem;
$size-space-three: 3rem;
$size-space-four: 4rem;