@import '../../../slick-ui/build/scss/_variables';

.rthfc .rt-thead.-headerGroups,
.rthfc .rt-thead.-header {
    z-index: 3;
}

.rthfc .rt-thead.-filters {
    z-index: 2;
}

/*------------ Sticky position version: -sp ------------*/

.rthfc.-sp .rt-tbody {
    overflow: visible;
    flex: 1 0 auto;
}

.rthfc.-sp .rt-thead {
    position: -webkit-sticky;
    position: sticky;
}

.rthfc.-sp .rt-tfoot {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    bottom: 0px;
}

.rthfc.-sp .rthfc-td-fixed {
    height: 101px;
    background-color: white;
}

.rthfc.-sp .rthfc-th-fixed {
    background-color: $color-environment-light-level-2-base;
}

.rthfc.-sp .rthfc-th-fixed,
.rthfc.-sp .rthfc-td-fixed {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
}

.rthfc.-sp .rthfc-th-fixed-left,
.rthfc.-sp .rthfc-td-fixed-left {
    left: 0;
}

.rthfc.-sp .rthfc-th-fixed-right,
.rthfc.-sp .rthfc-td-fixed-right {
    background-color: $color-environment-light-level-4-base;
    color: $color-content-default;
    left: 0;
}

/*------------ scroll event version: -se ------------*/
/* IE11 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .rthfc.-se .-header .rt-th.rthfc-th-fixed,
    .rthfc.-se .-headerGroups .rt-th.rthfc-th-fixed,
    .rthfc.-se .-filters .rt-th.rthfc-th-fixed,
    .rthfc.-se .rt-td.rthfc-td-fixed {
        height: 100%;
    }
}

.rthfc.-se .-header .rt-th.rthfc-th-fixed,
.rthfc.-se .-headerGroups .rt-th.rthfc-th-fixed,
.rthfc.-se .-filters .rt-th.rthfc-th-fixed,
.rthfc.-se .rt-td.rthfc-td-fixed {
    position: relative;
    z-index: 1;
}
